import { ReactElement } from 'react';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import Page from 'src/components/Page';
import PageNotFound from '@components/page/404';

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <PageNotFound />
    </Page>
  );
}

Page404.getLayout = function getLayout(page: ReactElement) {
  return <LogoOnlyLayout>{page}</LogoOnlyLayout>;
};
